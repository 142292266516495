var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"my-2"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingImage),expression:"loadingImage"}],staticClass:"text-center text-xl text-gray-400",style:({ height: '200px' })},[_vm._v(" Laster bilde ")]),_c('picture-input',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loadingImage),expression:"!loadingImage"}],ref:"pictureInput",class:("" + ((_vm.validation.imageUrl.valid === false && 'border border-red-500 border-dashed') || '')),style:({ width: (_vm.validation.imageUrl.valid === false && 'max-content') || '100%' }),attrs:{"crop":false,"prefill":_vm.imageFile,"width":"320","height":"200","margin":"0","accept":"image/*","size":"10","buttonClass":"btn","customStrings":{
        upload:
          (_vm.validation.imageUrl.valid === false && 'Trenger bilde') ||
          'Slipp, trykk, eller lim inn bilde*',
        drag:
          (_vm.validation.imageUrl.valid === false && 'Trenger bilde') ||
          'Slipp, trykk, eller lim inn bilde*',
      }},on:{"change":_vm.imageInputChange}})],1),_c('form',{on:{"submit":_vm.submitForm}},[_c('div',[_c('TextInput',{attrs:{"type":"text","placeholder":"Navn","required":"","error":_vm.validation.title.valid === false},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('TextInput',{attrs:{"type":"text","placeholder":"Pris","required":"","error":_vm.validation.price.valid === false},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}}),_c('TextInput',{attrs:{"type":"text","placeholder":"Beskrivelse"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('TextInput',{attrs:{"type":"text","placeholder":"Butikk"},model:{value:(_vm.dealer),callback:function ($$v) {_vm.dealer=$$v},expression:"dealer"}}),_c('DateInput',{attrs:{"label":"Gyldig til","placeholder":"Vet ikke","error":_vm.validation.validThrough.valid === false},model:{value:(_vm.validThrough),callback:function ($$v) {_vm.validThrough=$$v},expression:"validThrough"}}),_c('div',{staticClass:"flex flex-col items-center"},[_c('button',{class:(((!_vm.valid && 'bg-green-300') || 'bg-green-600') + " text-white font-semibold hover:text-white py-2 px-4 border border-blue hover:border-transparent rounded"),attrs:{"type":"submit"}},[_vm._v(" Send inn ")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }