/* eslint camelcase: 0 */
import { apiUrl } from "../config/vars";
import { getIdentityId } from "../config/aws";
import { getCustomOffer } from "../helpers";

/**
 *
 * @param {string} offer_uri
 * @returns {Optional<object>}
 */
export const promoteOffer = async (offer_uri) => {
  const { data: identity_id, error } = await getIdentityId();
  if (error) {
    return error;
  }
  const headers = {
    "Content-Type": "application/json",
  };
  const options = {
    method: "PUT",
    body: JSON.stringify({
      offer_uri,
      identity_id,
    }),
    headers,
  };
  const response = await fetch(`${apiUrl}/offers/promoted`, options);
  if (response.ok) {
    return {
      ok: true,
      data: {},
    };
  }
  return {
    ok: false,
  };
};

export const saveCustomOffer = async (offer) => {
  const { data: identity_id, error } = await getIdentityId();
  if (error) {
    return error;
  }

  const customOffer = getCustomOffer(offer);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Cognito ${identity_id}`,
  };
  const options = {
    method: "POST",
    headers,
    body: JSON.stringify({
      ...customOffer,
      identity_id,
      productCollection: "groceryoffers",
      market: "no",
    }),
  };
  const response = await fetch(`${apiUrl}/offers`, options);
  if (response.ok) {
    return {
      ok: true,
      data: {},
    };
  }
  return {
    ok: false,
  };
};

export const getOffer = async (offerUri) => {
  const path = `/offers/${offerUri}`;
  const options = {
    method: "GET",
  };
  try {
    const response = await fetch(`${apiUrl}${path}`, options);
    if (response.ok) {
      return { ok: true, data: await response.json() };
    }
    throw new Error(`Could not get product ${offerUri} from Server.`);
  } catch (error) {
    console.error(error);
    return { ok: false, error };
  }
};
