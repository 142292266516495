<template>
  <button v-bind="$attrs" v-on="$listeners" :class="computedClass" :disabled="disabled">
    <slot>{{ text }}</slot>
  </button>
</template>

<script>
export default {
  name: "MyButton",
  props: {
    text: String,
    disabled: Boolean,
  },
  computed: {
    computedClass() {
      const baseClass = "uppercase font-semibold py-2 px-4 rounded";
      let extraClass = "";
      if (this.disabled) {
        extraClass = "bg-primary-bg-color-200 text-primary-color-200";
      } else {
        extraClass = "bg-primary-bg-color text-primary-color";
      }
      return `${baseClass} ${extraClass}`;
    },
  },
};
</script>
