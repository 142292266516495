import { s3BucketRegion, s3BucketName, cognitoIdentityPoolId } from "./vars";
import { loadAsync } from "../init-browser-libs";

let credentials;
let s3;
let config;

export const getIdentityId = async () => {
  try {
    await loadedAwsSdk();
    return { data: credentials.params.IdentityId };
  } catch (error) {
    console.error(error);
    return { error };
  }
};

export const uploadToS3 = async ({ Key, Body, ContentType }) => {
  try {
    await loadedAwsSdk();
    const s3Response = await s3.upload({ Key, Body, ContentType }).promise();
    return { data: s3Response };
  } catch (error) {
    console.error(error);
    return { error };
  }
};

const loadedAwsSdk = async () => {
  if (window.AWS) {
    if (credentials.needsRefresh()) {
      credentials.refresh();
    }
    return true;
  }
  await loadAsync("https://cdnjs.cloudflare.com/ajax/libs/aws-sdk/2.493.0/aws-sdk.min.js");
  setUpAwsSdk();
  return true;
};

const setUpAwsSdk = () => {
  config = window.AWS.config;
  credentials = new window.AWS.CognitoIdentityCredentials({
    IdentityPoolId: cognitoIdentityPoolId,
  });
  config.update({
    region: s3BucketRegion,
    credentials,
  });
  if (credentials.needsRefresh()) {
    credentials.refresh();
  }
  s3 = new window.AWS.S3({
    apiVersion: "2006-03-01",
    params: { Bucket: s3BucketName },
  });
};

// Get the credentials on load to reduce delay when submitting.
getIdentityId();
