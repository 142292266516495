<template>
  <div class="text-left" v-if="label">
    <div>
      <span>{{ label }}</span>
    </div>
    <Datepicker
      @input="handleInputChange"
      :value="value"
      :placeholder="`${placeholder}${(required && '*') || ''}`"
      :input-class="`w-full bg-gray-200 px-3 h-10 ${error && 'bg-red-200'}`"
      wrapper-class="leading-normal border h-10 border-gray-light rounded focus:border-blue focus:shadow mb-4 w-full"
    />
  </div>
  <Datepicker
    v-else
    @input="handleInputChange"
    :value="value"
    :placeholder="`${placeholder}${(required && '*') || ''}`"
    :input-class="`w-full h-full bg-gray-200 px-3 ${error && 'bg-red-200'}`"
    wrapper-class="flex-shrink flex-grow leading-normal flex-1 border h-10 border-gray-light rounded focus:border-blue focus:shadow flex flex-wrap items-stretch mb-4 relative w-full"
  />
</template>

<script>
import Datepicker from "vuejs-datepicker";

export default {
  name: "DateInput",
  components: { Datepicker },
  props: {
    placeholder: String,
    value: String,
    error: Boolean,
    required: Boolean,
    label: String,
  },
  methods: {
    handleInputChange(value) {
      if (typeof this.value === "string") {
        this.$emit("input", value.toISOString().split("T")[0]);
      } else {
        this.$emit("input", value);
      }
    },
  },
};
</script>

<style></style>
