<template>
  <div>
    <Header />
    <div class="container mx-auto">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Header from "./components/Header.vue";

export default {
  name: "Layout",
  components: {
    Header,
  },
};
</script>

<style></style>
