import exif from "exif-js";
import Compressor from "compressorjs";

/**
 * @typedef Geolocation
 * @type {object}
 * @property {number} lat
 * @property {number} lng
 */
/**
 * @typedef ImageMetadata
 * @type {object}
 * @property {array} GPSLongitude
 * @property {array} GPSLatitude
 * @property {string} DateTime
 * @property {string} DateTimeOriginal
 */

/**
 * Converts an array of [lon|lat, minutes, seconds] to a number.
 *
 * @param {array} geo
 * @returns {number}
 */
export const geo2dec = (geo) => geo[0] + geo[1] / 60 + geo[2] / 3600;

/**
 *
 * @param {File} file
 * @returns {Promise<string>}
 */
export const getBase64StringFromFile = async (file) => {
  const compressedImage = await compressImage(file);
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      resolve(event.target.result);
    };
    fileReader.readAsDataURL(compressedImage);
    fileReader.onerror(reject);
    fileReader.onabort(reject);
  });
};

/**
 *
 * @param {File|Blob} imageFile
 * @param {object} [options={maxHeight: 256}]
 * @returns {Promise<File|Blob>}
 */
export const compressImage = (imageFile, options = { maxHeight: 256 }) =>
  new Promise((resolve, reject) => {
    new Compressor(imageFile, {
      options,
      success: resolve,
      error: reject,
    });
  });

/**
 *
 * @param {string} timestamp
 * @returns {Date}
 */
export const getDateTimeFromExifTime = (timestamp) =>
  new Date(
    `${timestamp
      .split(" ")
      .map((x, i) => (i === 0 ? x.replace(/:/g, "-") : x.replace(":", ":")))
      .join("T")}Z`,
  );

/**
 * Gets image metadata from an image in a <img/> element.
 *
 * @param {HTMLImageElement|Image} imageElement
 * @returns {ImageMetadata|null}
 */
export const getImageMetadata = (imageElement) =>
  new Promise((resolve, reject) => {
    exif.getData(imageElement, function() {
      try {
        const metadata = exif.getAllTags(this);
        resolve(metadata);
      } catch (error) {
        console.warn(error);
        reject(null);
      }
    });
  });

/**
 * Gets decimal coordinates from a image metadata object with Exif geolocation.
 *
 * @param {ImageMetadata} metadata
 * @returns {Geolocation|null}
 */
export const getCoordinates = (metadata) => {
  try {
    return {
      lng: geo2dec(metadata.GPSLongitude),
      lat: geo2dec(metadata.GPSLatitude),
    };
  } catch (error) {
    return null;
  }
};

/**
 * Gets timestamp of an image metadata object.
 *
 * @param {ImageMetadata} metadata
 * @returns {Date|null}
 */
export const getDateTime = (metadata) => {
  if (metadata.DateTime) return getDateTimeFromExifTime(metadata.DateTime);
  if (metadata.DateTimeOriginal) return getDateTimeFromExifTime(metadata.DateTimeOriginal);
  return null;
};
