<template>
  <div class="flex flex-wrap items-stretch w-full mb-4 relative" v-if="label">
    <div class="flex -mr-px">
      <span
        class="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark text-sm"
        >{{ label }}</span
      >
    </div>
    <input
      :class="
        `flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded px-3 relative focus:border-blue focus:shadow bg-gray-200 flex flex-wrap items-stretch w-full mb-4 relative ${error &&
          'bg-red-200'}`
      "
      :placeholder="`${placeholder}${(required && '*') || ''}`"
      @input="$emit('input', $event.target.value)"
      :type="$attrs.type"
      :value="value"
    />
  </div>
  <input
    type="text"
    v-else
    :class="
      `flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded px-3 relative focus:border-blue focus:shadow bg-gray-200 flex flex-wrap items-stretch w-full mb-4 relative ${error &&
        'bg-red-200'}`
    "
    :placeholder="`${placeholder}${(required && '*') || ''}`"
    :value="value"
    @input="$emit('input', $event.target.value)"
  />
</template>

<script>
export default {
  name: "TextInput",
  props: {
    placeholder: String,
    value: String,
    error: Boolean,
    required: Boolean,
    label: String,
  },
};
</script>

<style></style>
