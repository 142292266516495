export const loadAsync = async (src) => {
  const script = document.createElement("script");
  script.type = "text/javascript";
  return new Promise((resolve) => {
    script.onload = function() {
      resolve(true);
    };
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        resolve(true);
      }
    };
    script.src = src;
    document.getElementsByTagName("head")[0].appendChild(script);
  });
};
