<template>
  <div class="bg-primary-bg-color text-primary-color p-4 flex justify-between">
    <h1 class="text-xl">Legg til tilbud</h1>
    <h1 class="text-lg underline">
      <a href="https://allematpriser.no">allematpriser.no</a>
    </h1>
  </div>
</template>

<script>
export default { name: "Header" };
</script>

<style></style>
