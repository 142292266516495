<template>
  <div>
    <div
      v-for="({ type, text, isRead }, index) in messages"
      :key="index"
      :style="{ position: 'fixed', bottom: 0, left: 0, width: '100vw' }"
      :class="
        `bg-primary-bg-color text-center text-md font-semibold ${
          type === 'error' ? 'text-red-500' : 'text-green-500'
        }`
      "
      v-show="!isRead"
    >
      <div class="m-4">
        {{ text }}
        <MyButton @click="() => clickMessage(index)" text="ok" class="border-2" />
      </div>
    </div>
  </div>
</template>

<script>
import MyButton from "./MyButton";
export default {
  name: "SnackbarMessages",
  components: {
    MyButton,
  },
  props: {
    messages: Array,
    clickMessage: Function,
  },
};
</script>
