/**
 *
 * @param {Event} event
 * @returns {File|null}
 */
export const getImageFromPasteEvent = (event) => {
  if (!event.clipboardData) throw new Error("Invalid paste event, no clipboardData.");
  const { files } = event.clipboardData;
  if (files && files.length > 0 && files[0]) {
    const file = files[0];
    if (file.type.startsWith("image/")) {
      return files[0];
    }
  }
  return null;
};

/**
 *
 * @param {string} str
 * @returns {string|null}
 */
export const extractShopgunOfferId = (str) => {
  const pattern = /(?:[^a-zA-Z0-9]|^)(?:([a-zA-Z0-9]{8})|([a-zA-Z0-9\_\-]{21}))(?:[^a-zA-Z0-9]|$)/;
  const matches = str.match(pattern);
  if (matches && matches.length > 0) {
    console.log("matches");
    console.log(matches);
    return matches.find((x) => !!x && x.startsWith && !x.startsWith("/"));
  }
  return null;
};
/**
 *
 * @param {string} str
 * @returns {string|null}
 */
export const extractOfferUri = (str) => {
  const _str = decodeURIComponent(str);
  const pattern = /([a-z\.]+:product:[A-Za-z0-9]+)/;
  const matches = _str.match(pattern);
  if (matches && matches.length > 0) {
    console.log("matches");
    console.log(matches);
    return matches[1];
  }
  return null;
};

/**
 *
 * @param {string} str
 * @returns {string|null}
 */
export const getOfferUriFromInput = (str) => {
  const offerUri = extractOfferUri(str);
  if (offerUri) {
    return offerUri;
  }
  const shopgunOfferId = extractShopgunOfferId(str);
  if (shopgunOfferId) {
    return `shopgun:product:${shopgunOfferId}`;
  }
  return null;
};

/**
 *
 * @param {string} str
 * @returns {boolean}
 */
export const isImageUrl = (str) => {
  const pattern =
    /(?:(?:https?:\/\/))[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b(?:[-a-zA-Z0-9@:%_+.~#?&/=]*(\.jpg|\.png|\.jpeg))/;
  return pattern.test(str);
};

/**
 *
 * @param {Date} date
 * @param {number} [days=0]
 * @returns {Date}
 */
export const addDaysToDate = (date, days = 0) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

/**
 *
 * @param {string|object} x
 * @returns {string}
 */
export const getOfferUri = (x) => {
  if (x.id && typeof x.id === "string") x = x.id;
  else {
    throw new Error(`Invalid input ${x}. Need string or offer object,`);
  }
  return `shopgun:product:${x}`;
};

/**
 *
 * @param {string} str
 * @returns {string|null}
 */
export const parsePrice = (str) => {
  return parseFloat(str.replace(",", "."));
};

export const getCustomOffer = (offer) => {
  let price = parsePrice(offer.price);
  let priceText = offer.price;
  if (Number.isFinite(price) && `${price}`.length >= offer.price.length) {
    priceText = "";
  } else {
    price = undefined;
  }
  return {
    ...offer,
    price,
    priceText,
  };
};
