<template>
  <Layout>
    <SubmitOfferPage />
    <SnackbarMessages
      :messages="$root.$data.state.messages"
      :clickMessage="(index) => $root.$data.readMessage(index)"
    />
  </Layout>
</template>

<script>
import SubmitOfferPage from "./components/SubmitOfferPage.vue";
import SnackbarMessages from "./components/SnackbarMessages.vue";
import Layout from "./Layout.vue";

export default {
  name: "App",
  components: {
    SubmitOfferPage,
    SnackbarMessages,
    Layout,
  },
  data() {
    return {
      //
    };
  },
};
</script>
