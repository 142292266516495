const initialState = {
  messages: [],
};
const store = {
  state: initialState,
  addMessage(message) {
    this.state.messages = [...this.state.messages, { ...message, isRead: false }];
  },
  readMessage(index) {
    const oldMessage = this.state.messages[index];
    this.state.messages = [
      ...this.state.messages.slice(0, index),
      Object.assign({}, oldMessage, { isRead: true }),
      ...this.state.messages.slice(index + 1),
    ];
  },
};

export default store;
