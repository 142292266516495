<template>
  <footer>
    <div class="flex flex-col items-center text-gray-600">
      <div class="my-2">Allematpriser.no</div>
      <div class="my-2">
        {{ gitSha }}
      </div>
    </div>
  </footer>
</template>

<script>
import { gitSha } from "../config/vars";

export default {
  data() {
    return {
      gitSha: gitSha || "deadbeef",
    };
  },
};
</script>
