<template>
  <div>
    <div class="my-2">
      <div
        class="text-center text-xl text-gray-400"
        :style="{ height: '200px' }"
        v-show="loadingImage"
      >
        Laster bilde
      </div>
      <picture-input
        v-show="!loadingImage"
        ref="pictureInput"
        @change="imageInputChange"
        :crop="false"
        :prefill="imageFile"
        width="320"
        height="200"
        margin="0"
        accept="image/*"
        size="10"
        buttonClass="btn"
        :style="{ width: (validation.imageUrl.valid === false && 'max-content') || '100%' }"
        :class="`${
          (validation.imageUrl.valid === false && 'border border-red-500 border-dashed') || ''
        }`"
        :customStrings="{
          upload:
            (validation.imageUrl.valid === false && 'Trenger bilde') ||
            'Slipp, trykk, eller lim inn bilde*',
          drag:
            (validation.imageUrl.valid === false && 'Trenger bilde') ||
            'Slipp, trykk, eller lim inn bilde*',
        }"
      />
    </div>
    <form @submit="submitForm">
      <div>
        <TextInput
          type="text"
          placeholder="Navn"
          v-model="title"
          required
          :error="validation.title.valid === false"
        />
      </div>
      <TextInput
        type="text"
        placeholder="Pris"
        v-model="price"
        required
        :error="validation.price.valid === false"
      />
      <TextInput type="text" placeholder="Beskrivelse" v-model="description" />
      <TextInput type="text" placeholder="Butikk" v-model="dealer" />
      <DateInput
        label="Gyldig til"
        v-model="validThrough"
        placeholder="Vet ikke"
        :error="validation.validThrough.valid === false"
      />
      <div class="flex flex-col items-center">
        <button
          type="submit"
          :class="`${
            (!valid && 'bg-green-300') || 'bg-green-600'
          } text-white font-semibold hover:text-white py-2 px-4 border border-blue hover:border-transparent rounded`"
        >
          Send inn
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { saveCustomOffer } from "../api/offers";
import PictureInput from "./PictureInput.vue";
import TextInput from "./TextInput.vue";
import DateInput from "./DateInput.vue";
import { uploadToS3 } from "../config/aws";
import { getImageFromPasteEvent, addDaysToDate } from "../helpers";
import { messageTypes } from "../enums";
import { getImageMetadata, getCoordinates } from "../image-utils";

const requiredFields = ["title", "price", "validThrough", "imageUrl"];

export default {
  name: "CustomOfferForm",
  components: {
    PictureInput,
    TextInput,
    DateInput,
  },
  props: {
    isLoadingImage: Boolean,
  },
  data() {
    return {
      title: "",
      price: "",
      description: "",
      imageUrl: "",
      dealer: "",
      location: null,
      validFrom: new Date(),
      validThrough: undefined,
      imageFile: null,
      msg: "",
      image: {},
      validation: requiredFields.reduce((acc, x) => ({ ...acc, [x]: { pristine: true } }), {}),
      isUploadingImage: false,
    };
  },
  computed: {
    loadingImage() {
      return this.isLoadingImage || this.isUploadingImage || false;
    },
    valid() {
      return this.title && this.price && this.imageUrl;
    },
    offer() {
      return {
        title: this.title,
        price: this.price,
        description: this.description,
        dealer: this.dealer,
        validFrom: this.validFrom,
        validThrough: this.validThrough,
        imageUrl: this.imageUrl,
        location: this.location,
      };
    },
  },
  watch: {
    offer({ title, price, imageUrl }) {
      if (title) {
        this.validation.title = { valid: true };
      } else if (!this.validation.title.pristine) {
        this.validation.title = { valid: false, message: "Trenger navn" };
      }
      if (price) {
        this.validation.price = { valid: true };
      } else if (!this.validation.price.pristine) {
        this.validation.price = { valid: false, message: "Trenger pris" };
      }
      if (imageUrl) {
        this.validation.imageUrl = { valid: true };
      }
      if (this.validation.title.valid && this.validation.price.valid && !imageUrl) {
        this.validation.imageUrl = { valid: false, message: "Trenger bilde" };
      }
    },
  },
  methods: {
    imageInputChange(image) {
      this.handleImageMetadata(image.imageFile);
      this.uploadImageToS3(image.fileName, image.imageFile, image.imageFile.type);
    },
    async handleImageMetadata(imageFile) {
      const metadata = await getImageMetadata(imageFile);
      if (metadata) {
        this.location = {
          geolocation: getCoordinates(metadata),
        };
      }
    },
    async uploadImageToS3(name, Body, ContentType) {
      const Key = `${new Date().getTime()}-${name}`;
      this.isUploadingImage = true;
      const { data } = await uploadToS3({ Key, Body, ContentType });
      if (data && data.Location) {
        this.imageUrl = data.Location;
      } else {
        this.$root.$data.addMessage({
          type: messageTypes.ERROR,
          text: "Kunne ikke lagre bildet ditt. Vennligst prøv igjen.",
        });
      }
      this.isUploadingImage = false;
    },
    async submitForm(event) {
      event.preventDefault();
      const { ok } = await saveCustomOffer(this.offer);
      if (ok) {
        this.$root.$data.addMessage({
          type: messageTypes.SUCCESS,
          text: "Tilbud er lagret",
        });
      } else {
        this.$root.$data.addMessage({
          type: messageTypes.ERROR,
          text: "Kunne ikke lagre tilbud",
        });
      }
    },
    handlePasteEvent(event) {
      const imageFile = getImageFromPasteEvent(event);
      if (imageFile) {
        this.imageFile = imageFile;
        this.uploadImageToS3(imageFile.name, imageFile, imageFile.type);
      }
    },
  },
  created() {
    document.addEventListener("paste", this.handlePasteEvent);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
