<template>
  <div class="mb-16 flex flex-col items-stretch text-center">
    <h2 class="text-2xl md:text-4xl">Send inn et tilbud</h2>
    <CustomOfferForm />
    <hr class="border my-4" />
    <Footer />
  </div>
</template>

<script>
import CustomOfferForm from "./CustomOfferForm.vue";
import Footer from "./Footer.vue";

export default {
  name: "SubmitOfferPage",
  components: {
    CustomOfferForm,
    Footer,
  },
};
</script>
